import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class FullNameColumn extends Column {
    title = 'table.employeesDirectory.fullName';
    property = null;
    sortable = true;
    sortField = 'lastName';
    type: CellDisplay = CellDisplay.avatar;
}
