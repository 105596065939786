import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Job } from '@models/company/job.model';

export class PositionColumn extends Column {
    title = 'table.employeesDirectory.position';
    property = 'job.title';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor(protected companyId: number) {
        super();

        const options = Job.param('company', this.companyId)
            .orderBy('title', 'ASC')
            .all()
            .then(([jobs]) =>
                jobs.map((job: Job) => ({
                    label: job.title,
                    value: job.id,
                }))
            );

        this.filter = new CheckListFilter('jobId', options, this.title);
    }
}
