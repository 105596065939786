import { Employee } from '@app/models/employee/employee.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { CheckListItem } from '@app/modules/table/classes/column-filters/check-list/check-list-item.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { LanguageService } from '@app/services';

export class StatusColumn extends Column {
    title = 'table.employeesDirectory.status';
    property = 'status';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
    filter?: CheckListFilter;

    constructor(private readonly languageService: LanguageService) {
        super();

        const options = [
            { label: 'employeeStatus.active', value: 'active', initiallyActive: true },
            { label: 'employeeStatus.onboarding', value: 'onboarding', initiallyActive: true },
            { label: 'employeeStatus.terminated', value: 'terminated' },
            { label: 'employeeStatus.onLeave', value: 'onLeave' },
        ];

        this.filter = new CheckListFilter('status', options, this.title);
    }

    getDisplayPropertyValue(employee: Employee): string {
        return employee.onLeave
            ? this.languageService.translate('onLeave')
            : (this.languageService.translate(employee.status ?? '') ?? '');
    }

    changeFilters(allowedValues: string[]): void {
        // Checklist filter
        const newOptions =
            this.filter?.options.filter((option: CheckListItem) => {
                return allowedValues.indexOf(option.value as string) > -1;
            }) ?? [];

        if (newOptions.length === 0) {
            this.filter = undefined;

            return;
        }

        if (this.filter) {
            this.filter.options = newOptions;
        }
    }

    getBadgeDisplay(employee: Employee): BadgeDisplay | undefined {
        if (!employee.isOnboardingPending) {
            return;
        }

        return;
    }

    shouldShowBadge(employee: Employee): boolean {
        return employee.isOnboardingPending;
    }
}
